<template>
  <div>
    <Header @loginUp="loginUp"></Header>
    <el-container style="padding: 15px 15px 10px 15px" class="container">
      <el-main class="main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Header from "../../components/public/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    loginUp() {
      window.sessionStorage.clear();
      sessionStorage.clear();
      window.location.reload()
      this.$message({
        type: "success",
        message: "退出成功",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main{
  height: calc(100vh - 60px);
  padding: 0 !important;
}
</style>